import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import { Link } from 'react-router-dom';
import './style.scss';
import AuthLayout from '../../components/AuthLayout';
import methodModel from '../../methods/methods';
import { useDispatch, useSelector } from 'react-redux';
import { login_success } from '../actions/user';
import { toast } from 'react-toastify';

const Login = () => {
  const history = useNavigate();
  const user = useSelector((state: any) => state.user);
  const [loginId, setLoginId] = useState()
  const dispatch = useDispatch();
  useEffect(() => {
    if (user && user?.loggedIn) {
      history('/dashboard')
    }
  }, [])


  const [ip, setIp] = useState('');
  const [username, setUsername] = useState('');
  const [attandanceEmail, setAttendanceEmail] = useState('');
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState('');
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false, currentPassword: false });
  const [step, setStep] = useState(1);
  const [otp, setOTP] = useState('');
  const [resp, setRes]: any = useState();
  useEffect(() => {
    let r = localStorage.getItem('remember')
    if (r) {
      let data = JSON.parse(r)
      setUsername(data.email)
      setPassword(data.password)
      setRemember(true)
    }

    // Using an HTTP GET request to ipinfo.io/json

    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        let ip = data.ip
        localStorage.setItem('IP', ip)
        setIp(ip)
      })
      .catch(error => console.error('Error fetching IP address:', error));


    let email = methodModel.getPrams('email')
    if (email) setUsername(email)
  }, [])
  let attendance = methodModel.getPrams('attendance')
  const setLogin = async (data: any) => {


    let url = '/profile'
    let eventId = methodModel.getPrams('eventId')

    if (eventId) {
      if (methodModel.getPrams('attended')) {
        try {
          const res = await ApiClient.get(`api/attandance?email=${data?.email}&eventId=${eventId}`);
        
          if (res.success === true) {
            url = `/thanku`
          }
        } catch (error) {
          console.error("Error fetching attendance:", error);
        }
      } else {
        url = `/event/detail/${eventId}`
      }
    }

    localStorage.setItem('token', data?.access_token )
  
    dispatch(login_success(data));

      history(url);
    
  
  }
  
  let userId = localStorage.getItem("id")
 
const resendOtp =()=>{
 let url  = 'api/resendOtp' ,
  data: any = {
 id:userId
};
  ApiClient.post(url, data)
  .then((res) => {
  
   toast(res?.message)
  })

}


  const hendleSubmit = (e: any) => {
    e.preventDefault()
    let data: any = {
      loginId: username,
      password,
      ip_address: ip
    };


    let url = 'api/user/login'
    if (step == 2) {
      url = 'api/two-factor/auth'
      data = {
        id: resp?._id,
        otp: otp,
        ip_address: ip
      }
    }

    loader(true)
    ApiClient.post(url, data).then(async res => {

      if (res.success == true) {
        localStorage.setItem('id', res?.data?._id)
        if (remember) {
          localStorage.setItem('remember', JSON.stringify(data))
        } else {
          localStorage.removeItem('remember')
        }
        if (res.data.two_factor_email_sent || step == 1) {
          setStep(2)
          setRes(res.data)
          // setLogin(res.data)
        } else {
          setLogin(resp)
        }
        const loginTime = new Date();
        localStorage.setItem('loginTime', loginTime.toISOString());
      }
      loader(false)
    })
  };
  const handleAttendence = (e: any) => {
    e.preventDefault()
    let eventId = methodModel.getPrams('eventId')
    loader(true)
    ApiClient.post('api/find/user', { email: attandanceEmail }, '', true).then(res2 => {

      if (res2.success) {
        let url = `/login?eventId=${eventId}&email=${attandanceEmail}&attended=true`
        history(url);
      } else {
        ApiClient.get(`api/get/login-id?email=${attandanceEmail}`)
          .then((res) => {
            if (res.success) {
            
              setLoginId(res?.data?.loginId)
              if (res?.data?.loginId) {
                let url = `/signup?eventId=${eventId}&email=${attandanceEmail}&loginId=${res?.data?.loginId}&attended=true`
                history(url);
              }
              else {
                let url = `/signup?eventId=${eventId}&email=${attandanceEmail}&attended=true`
                history(url);
              }

            } 
            else{
              let url = `/signup?eventId=${eventId}&email=${attandanceEmail}&attended=true`
              history(url); 
            }
          })
       
      }
      loader(false)
    })
  };
  const [emailError, setEmailError] = useState('');

  const validateEmail = (email :any) => {
    // Email regex pattern for basic validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e :any) => {
    const email = e.target.value;
    setAttendanceEmail(email);

    if (email.trim() === '') {
      setEmailError('Email address is required');
    } else if (!validateEmail(email)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };
  return (
    <>
      <AuthLayout>
        {attendance === "true" ? <form onSubmit={handleAttendence}>
          <div>
            {/* <input
              type="email"
              className="shadow-box bg-white mb-6 w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
              placeholder="Email address"
              value={attandanceEmail}
              onChange={(e) => setAttendanceEmail(e.target.value)}
              required
            /> */}
             <input
        type="email"
        className={`shadow-box bg-white mb-6 w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 ${
          emailError ? 'border-red-500' : '' // Add red border if there's an error
        }`}
        placeholder="Email address"
        value={attandanceEmail}
        onChange={handleEmailChange}
        required
      />
      {emailError && <p className="text-red-500 text-xs">{emailError}</p>}
          </div>
          <div className="mt-8">
            {/* <label><input type="checkbox" checked={remember} onChange={(e)=>setRemember(e.target.checked)} className="mr-2" /> Remember Me</label> */}
            <button
              type="submit"
              className="!px-4 w-full text-sm font-normal text-white h-11 flex items-center justify-center gap-2 !bg-orange-500 rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
              Mark Attendence
            </button>
          </div>
        </form> :
          <form onSubmit={hendleSubmit}>
            <h4 className="text-typo mb-6 text-2xl font-medium">
              Sign in
            </h4>
            {step == 1 ? <>
              <input
                type="text"
                className="shadow-box bg-white mb-6 w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                placeholder="Login Id"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                // disabled={methodModel.getPrams('attended')?true:false}
                required
              />
              <div className="relative mb-6">
                <input
                  type={eyes.password ? "text" : "password"}
                  className="shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
                {eyes.password ? (
                  <i
                    className="fa fa-eye top-3 right-3 absolute text-gray-600"
                    onClick={() =>
                      setEyes({ ...eyes, password: !eyes.password })
                    }
                  />
                ) : (
                  <i
                    className="fa fa-eye-slash top-3 right-3 absolute text-gray-600"
                    onClick={() =>
                      setEyes({ ...eyes, password: !eyes.password })
                    }
                  />
                )}
              </div>
            </> : <>

              <p className="mb-2">OTP sent on email</p>
              <input
                type="text"
                maxLength={6}
                minLength={6}
                className="shadow-box bg-white mb-6 w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
                required
              />


            </>}

            <div className='flex'>
          
            {step == 1 ? <>
              <label className='flex items-center pointer'><input type="checkbox" checked={remember} onChange={(e) => setRemember(e.target.checked)} className="mr-2 h-4 w-4" /> <span className='text-md text-gray-600'>Remember Me</span></label>
              <Link className="sign_up ml-auto text-orange-500" to="/forgotpassword"> Forgot Password</Link>
            </>: <p className="sign_up ml-auto text-orange-500 cursor-pointer " onClick={resendOtp} > Resend OTP</p>}  
            </div>
            <div className="mt-8">
              {/* <label><input type="checkbox" checked={remember} onChange={(e)=>setRemember(e.target.checked)} className="mr-2" /> Remember Me</label> */}
              <button
                type="submit"
                className="!px-4 w-full text-sm font-normal text-white h-11 flex items-center justify-center gap-2 !bg-orange-500 rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                Sign in
              </button>
            </div>
            <p className='text-sm mt-3 text-center'>Don't have an account? <Link to="/signup" className='text-orange-500 text-sm'>Sign Up</Link></p>
          </form>}



      </AuthLayout>
    </>
  );
};

export default Login;