import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import shared from "./shared";
import environment from "../../environment";
import { useSelector } from "react-redux";
import { FiPlus, FiTrash } from "react-icons/fi";
import { toast } from "react-toastify";

const AddEdit = () => {
    const { id } = useParams()
    const history = useNavigate()
    const user = useSelector((state) => state.user);
    const [submitted, setSubmitted] = useState(false)
    const [users, setUsers] = useState([])
    const [members, setMember] = useState([{ firstName: '', lastName: "", email: '', fullName: "" }])

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let method = 'post'
        let url = shared.addApi
        let value = {
            data: members.map(itm => {
                return {
                    ...itm,
                    role: 'member',
                    addedBy: user._id,
                    groupId: user.groupId._id,
                    fullName: (itm?.firstName ?? '') + ' ' + (itm?.lastName ?? '')

                }
            })
        }
        if (value.id) {
            method = 'put'
            url = 'api/attendees/update'
        } else {
            delete value.id
        }

        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                toast(res?.message)
                history(`/${shared.url}`)
            }
            loader(false)
        })
    }

    const getUsers = (p = {}) => {
        let f = {
            ...p,
            role: environment.userRoleId,
            groupId: user?.groupId?._id || '',
            isDeleted: false
        }
        ApiClient.get('api/user/members', f).then(res => {
            if (res.success) {
                let data = res.data.map(itm => {
                    return { ...itm, id: itm._id }
                })
                data = data.filter(itm => itm.email != user.email)
                setUsers(data)
            }
        })
    }

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get('api/attendees/detail', { id }).then(res => {
                if (res.success) {
                    let value = res.data
                    // let payload = form

                    // Object.keys(payload).map(itm => {
                    // payload[itm] = value[itm]
                    // })

                    // payload.id = id
                    // setform({
                    // ...payload
                    // })

                }
                loader(false)
            })
        }
        getUsers()
    }, [id])

    const addMore = () => {
        let arr = members
        let payload = { fullName: '', email: '', lastName: "", firstName: "" }
        arr.push(payload)
        setMember([...arr])
    }

    // const updateMember = (i,key='',value='') => {
    // let arr = members
    // arr[i][key]=value
    // setMember([...arr])
    // }
    const updateMember = (index, field, event) => {
        const value = event.target.value.replace(/\s/g, ''); // Remove spaces from the input value
        const updatedMembers = [...members];
        updatedMembers[index][field] = value;
        setMember(updatedMembers);
    };

    const updateMemberAll = (i, values) => {
        let arr = members
        arr[i] = values
        setMember([...arr])
    }

    const removeMember = (i) => {
        let arr = members
        arr = arr.filter((itm, ind) => ind != i)
        setMember([...arr])
    }

    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">

                    <div className="flex justify-between gap-2">
                        <div className='flex items-center mb-8'>
                            <Tooltip placement="top" title="Back">

                                <Link to={`/${shared.url}`} className="!px-4 py-2 flex items-center justify-center rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all gap-1 mr-2"><i className='fa fa-angle-left text-lg'></i></Link>
                            </Tooltip>
                            <div>
                                <h3 className="text-lg lg:text-2xl font-semibold text-[#111827]">
                                    {/* {id ? 'Edit' : 'Add'} */}
                                    {shared.title}
                                </h3>
                                {/* <p class="text-sm font-normal text-[#75757A]">Here you can see all about your {shared.title}</p> */}
                            </div>
                        </div>

                     

                        {/* <div className="text-right mt-3">
<button type="button" onClick={addMore} className="text-white bg-orange-400 bg-orange-600 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center gap-2"><FiPlus/> Add More</button> 
</div> */}
                    </div>

                    <div className={`grid grid-cols-1 gap-4 ${members.length >= 2 ? "lg:grid-cols-2" : "lg:grid-cols-1"}`}>

                        {members.map((itm, i) => {
                            return <>
                                <div className="border border-gray-200 rounded-xl p-4 flex flex-col gap-4">
                                    <div className="">
                                  
                                        <FormControl
                                            type="select"
                                            displayValue="email"
                                            className="lowecase"
                                            valueType="object"
                                            label="Select Member"
                                            theme="search"
                                            value={itm.memberId}
                                            placeholder="Search by Email"
                                            onInputChange={e => {
                                             
                                                // updateMember(i,'email',e)
                                            }}
                                            options={[
                                                // { id: '', email: 'Add New Member' },
                                                ...users
                                            ]}
                                            onChange={e => {
                                               
                                                if (e?.id) {
                                                    updateMemberAll(i, { ...itm, memberId: e.id, email: e.email, fullName: e.fullName, lastName: e.lastName, firstName: e.firstName })
                                                } else {
                                                    updateMemberAll(i, { ...itm, memberId: '', email: '', fullName: '', lastName: "", firstName: "" })
                                                }
                                            }}
                                            required
                                        />
                                    </div>

                                    <div className="flex gap-4 items-center w-full">

                                 

                                    {itm.memberId ? <></> : <>
                                        <div className="w-full" >
                                            {/* <FormControl
                                                className="capitalize"
                                                type="email"
                                                label="Email"
                                                value={itm.email}
                                                onChange={e => updateMember(i, 'email', e)}
                                                required
                                            /> */}
                                            <label >Email</label>
                                            <input
                                                type="email"
                                                name="Email"
                                                placeholder="Email"
                                                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                                                value={itm.email}
                                                onChange={e => updateMember(i, 'email', e)}
                                                // onKeyPress={(e) => {
                                                //     if (e.key === ' ') e.preventDefault(); // Prevent space
                                                // }}
                                                required
                                            />
                                        </div>
                                    </>}

                                    <div className="capitalize w-full">
                                        <div>
                                            <label >First Name</label>
                                        </div>
                                        <input
                                            type="text"
                                            name="firstName"
                                            placeholder="First Name"
                                            className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                                            value={itm.firstName}
                                            onChange={(e) => updateMember(i, 'firstName', e)}
                                            onKeyPress={(e) => {
                                                if (e.key === ' ') e.preventDefault(); // Prevent space
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className="w-full">
                                        <div>
                                            <label>Last Name</label>
                                        </div>
                                        <input
                                            type="text"
                                            name="lastName"
                                            placeholder="Last Name"
                                            className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"

                                            value={itm.lastName}
                                            onChange={(e) => updateMember(i, 'lastName', e)}
                                            onKeyPress={(e) => {
                                                if (e.key === ' ') e.preventDefault(); // Prevent space
                                            }}
                                            required
                                        />
                                    </div>
                                    </div>

                                    <div className="flex items-center justify-end ">
                                        {members.length > 1 ? <>
                                            <button  onClick={() => removeMember(i)} className="text-white bg-red-500  justify-center bg-red-500 flex items-center gap-2 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-2 py-1 md:px-5 md:py-2.5 text-center">
                                                <FiTrash /> Delete
                                            </button>
                                        </> : <></>}

                                    </div>

                                </div>
                            </>
                        })}

                  

                    </div>
                    <div className="flex items-center justify-end gap-4 mt-6">
                    <div className="">
                            <button type="button" onClick={addMore} className="text-primary border  border-orange-600 hover:bg-orange-600 hover:!text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center gap-2"><FiPlus /> Add More</button>
                        </div>

                        <button type="submit" className="text-white focus:ring-4 focus:outline-none hover:opacity-80 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
                    </div>

                </div>

            </form>
        </Layout>
    </>
}

export default AddEdit
