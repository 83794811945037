import React, { useEffect, useState } from 'react'
import PageLayout from '../components/global/PageLayout'
import ApiClient from '../methods/api/apiClient'
import loader from '../methods/loader'

const PrivacyPolicy = () => {
  const [data ,setData] = useState()
  const getData = () => {
    loader(true)

    ApiClient.get('api/contentManagement/detail?slug=privacy-policy')
        .then(res => {
            if (res.success) {
              setData(res?.data)
                loader(false);
               
            }
          
        });
}
const scrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling
  });
};
useEffect(() => {
  getData()
  scrollToTop()
}, []);
  return (
    <div>
     <PageLayout>
        <div className='container mt-10 mx-auto py-12 px-6 bg-white rounded-xl border border-gray-100 shadow-md'>
      
        <h1 class="text-2xl font-bold text-center mb-8">Privacy and Policy</h1>

    
        <section class="mb-6">
            <h2 class="text-2xl font-semibold mb-2">1. Introduction</h2>
        </section>


        <div className='' dangerouslySetInnerHTML={{__html:data?.description || '--'}}></div>
        </div>
     </PageLayout>
    </div>
  )
}

export default PrivacyPolicy
