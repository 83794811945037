import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from "react-router-dom";
import ApiClient from "../methods/api/apiClient";
import loader from '../methods/loader';
import { MdOutlineEmail } from 'react-icons/md';
import { GoHistory } from "react-icons/go";
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { LuUser2, LuUserPlus } from 'react-icons/lu';
import { MdClear } from "react-icons/md";
import axios from 'axios';
import { TbFileExport } from "react-icons/tb";
import environment from '../environment';
import Modal from "../components/common/Modal";
import AddNewGroup from './Events/AddNewGroup';
import { IoMdClose, IoMdCloudDownload, IoMdCloudUpload } from 'react-icons/io';
import { FiPlus, FiTrash } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import PageLayout from '../components/global/PageLayout';
import { toast } from 'react-toastify';
import datepipeModel from '../models/datepipemodel';
import GroupHistory from "./GroupHistory"
import { useSelector } from "react-redux";
import { BsCheck, BsCrosshair, BsTrash3 } from "react-icons/bs";
import { Tooltip } from 'antd';
import { decryptId } from '../components/common/Encryption/encryption';
const Groups = ({ eventDetail = '', dataa = '', stateData = "" }) => {

    const { eventid } = useParams()
    const id = decryptId(eventid)
    const location = useLocation();
    const history = useNavigate();
    const user = useSelector((state) => state.user);
    const receivedStateData = location.state;
    const [groupList, setGroupList] = useState()
    const [attendeesGroup, setattendeesGroup] = useState()
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [requestGroup, setRequestGroup] = useState(false)
    const [requestGroupList, setRequestGroupList] = useState("")
    const [historyData, setHistoryData] = useState()
    const [openHistoryData, setOpenHistoryData] = useState(false)
    const [historyModal, setHistoryModal] = useState(false)
    const [data, setData] = useState()
    const [showDiv, setShowDiv] = useState(false)
    const eventIdd = receivedStateData?.eventId?.id
    const groupId = receivedStateData?.dataa?.data?.groupId?._id || receivedStateData?.groupId?._id
   

    const handleCloseModal = () => {
        setShowDiv(false)
    }
    const importFile = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64String = event.target.result;

                let payload = {
                    groupId: groupId,
                    eventId: eventIdd,
                    base64String: base64String
                };

                ApiClient.post(`api/import/event-group`, payload).then(res => {
                    if (res.success) {
                        toast.success(res.message);
                    }
                });
            };

            reader.readAsDataURL(file);
        }
    };

    const handleSelectValue = (e, element) => {
        loader(true)
        let payload = {
            data: [{
                id: element?.id,
                ConnectMeetId: e?.target?.value,
                attendeeId: element?.attendeesDetails?._id,
                eventId: element?.attendeesDetails?.eventId,
                groupId: element?.attendeesDetails?.groupId
            }]
        };
        ApiClient.put('api/event/group/edit', payload).then(res => {
            if (res.success) {
                loader(false)
                e.target.value = "";
                getGroupPair()
            }
        })
    }
    const getGroupPair = () => {
        loader(true)
        ApiClient.get(`api/event/group/list?eventId=${eventIdd}`).then(res => {
            if (res.success) {
                loader(false)
                setGroupList(res?.data)
            }
        })
    }
    const getGroupRequest = () => {
        let payload = {
            eventId: eventIdd
        }
        ApiClient.get(`api/group-request/list`, payload).then(res => {
            if (res.success) {
                setRequestGroupList(res?.data)
            }
        })
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling
        });
    };
    const getGroupExport = async () => {
        try {

            const res = await axios({
                method: "get",
                url: `${environment.api}api/export/event-group?eventId=${eventIdd}`,
                responseType: 'blob',

            });
            var blob = new Blob([res.data], {
                type: res.headers["content-type"],
            });
            let downloadAnchor = document.createElement("a")
            downloadAnchor.href = window.URL.createObjectURL(blob);
            downloadAnchor.download = `GroupsDetail.xlsx`;
            downloadAnchor.click()


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        // getAttendeesGroupPair()
        getGroupPair()
        getGroupRequest()
        scrollToTop();
    }, [])
    const handleInputField = async () => {
        try {

            const res = await axios({
                method: "get",
                url: `${environment.api}api/sample/group-import`,
                responseType: 'blob',

            });
            var blob = new Blob([res.data], {
                type: res.headers["content-type"],
            });
            let downloadAnchor = document.createElement("a")
            downloadAnchor.href = window.URL.createObjectURL(blob);
            downloadAnchor.download = `SampleFile.xlsx`;
            downloadAnchor.click()


        } catch (error) {
            console.error('Error fetching data:', error);
        }


    };

    // const handleCheckboxClick = (e, email) => {
    //     if (e.target.checked) {
    //         setSelectedEmails(prevSelectedEmails => [...prevSelectedEmails, email]);
    //     } else {
    //         setSelectedEmails(prevSelectedEmails => prevSelectedEmails.filter(selectedEmail => selectedEmail !== email));
    //     }
    // };
    const [selectedAttendee, setSelectedAttendee] = useState(null);
    const [selectedName, setSelectedName] = useState(null);
    const handleCheckboxClick = (e, email, name) => {

        if (e.target.checked) {
            // setSelectedEmails([email]);
            setSelectedName(name)
            setSelectedAttendee(email)
            setOpenHistoryData(true)
            scrollToTop();
            loader(true)
            ApiClient.get(`api/user/historic/data?loginId=${email}&groupId=${user?.groupId?._id}&eventId=${id}`).then(res => {
                if (res.success) {
                    setHistoryData(res?.data)
                    loader(false)
                }
            })
        } else {
            // If a radio button is unchecked, don't do anything for now
        }
    };

    const getInvites = () => {
        loader(true)
        let f = {
            eventId: receivedStateData?.eventId?.id,
            groupId: groupId,
            groupNo: "",
            attendeeIds: [

            ]
        }
        ApiClient.post('api/add/group-event', f).then(res => {
            if (res) {
                toast.success(res.message)
                loader(false)
                getGroupPair()
            }
        })
    }
    const handleSelectAllClick = (e, attendees) => {
        const emails = attendees?.map(attendee => attendee?.attendeesDetails?.email);
        if (e?.target?.checked) {
            setSelectedEmails(prevSelectedEmails => [...prevSelectedEmails, ...emails]);
            // setHistoryModal(true)
        } else {
            setSelectedEmails(prevSelectedEmails => prevSelectedEmails.filter(selectedEmail => !emails.includes(selectedEmail)));
        }
    };
    // const getselectedEmailsData = () => {

    //     const emails = selectedEmails.join(',');
    //     loader(true)
    //     ApiClient.get(`api/user/historic/data?email=${emails}`).then(res => {
    //         if (res.success) {
    //             setHistoryData(res?.data)
    //             loader(false)
    //         }
    //     })
    // }
    const handleDeleteIcon = (ele) => {

        loader(true)
        ApiClient.delete(`/api/group-event/remove?eventId=${ele?._id?.eventId}&connectMeetId=${ele?._id?.ConnectMeetId}`).then(res => {
            if (res) {
                toast.success(res.message)
                loader(false)
                getGroupPair()
            }
        })
    }

    const sendGroupMail = () => {
        loader(true)
        let payload = {
            eventId: id,
            groupId: user?.groupId?._id
        }
        ApiClient.post(`api/send-email/event-group`, payload).then(res => {
            if (res.success) {
                loader(false)
                toast.success(res.message)

            }
        })
    }
    const handleclose = () => {
        setOpenHistoryData(false)
        setSelectedAttendee(null)
    }
    const [transformedArray, setTransformedArray] = useState([]);
    const transformAndSetState = (list) => {
        const transformedArray = list?.map((item, index) => ({
            _id: item._id?.ConnectMeetId,
            name: index + 1,
        }));
        setTransformedArray(transformedArray);
    };
    useEffect(() => {
        transformAndSetState(groupList);
    }, [groupList]);



    return (
        <>



            <PageLayout>

                <div className="bg-white border-gray-200 px-4 pt-8 lg:px-6 py-2.5 dark:bg-gray-800">
                    <div className="container mx-auto">
                        <div
                            className="mb-4 pt-1  pointer"
                            onClick={() => history(-1)}
                        >
                            <span>


                                <i className="fa fa-arrow-left mr-2"></i>
                            </span>
                            Back
                        </div>
                        {/* <div>
                            <h4>{groupList[0]?.eventsDetails?.title}</h4>
                            <p> {datepipeModel.datetime(groupList[0]?.eventsDetails?.date)}</p>
                        </div> */}
                        {/* <buuton onClick={getselectedEmailsData}>Search</buuton> */}
                        <div className=''>
                            <div className='flex items-center gap-4  mb-3'>

                                <div className='flex items-center justify-between flex-wrap w-full'>
                                    <div className='mt-2 flex  items-center gap-2'>
                                        <div className=''>
                                            <button className='w-full text-sm bg-orange-500 rounded-lg px-1 lg:px-4 py-2 text-white flex items-center gap-4 justify-center font-bold' onClick={getInvites}><FiPlus /> Add New Group</button>
                                        </div>
                                        <div className=''>
                                            <button className='w-full text-sm bg-[#393c3d] rounded-lg px-4 py-2 text-white flex items-center gap-4 justify-center font-bold' onClick={() => setShowDiv(true)}><LuUserPlus /> ConnectMeet Requests</button>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-2 flex-wrap'>


                                        {/* <div className=''> 
                                            <div className="flex  items-center justify-center bg-grey-lighter">
                                                <label className=" flex gap-2 items-center border-dashed border-gray-200 items-center px-2 py-2 bg-white text-blue rounded-lg tracking-wide  border border-blue cursor-pointer ">
                                                    <IoMdCloudDownload className='text-md' />

                                                    <span className="text-sm leading-normal" onClick={() => handleInputField()}>Sample file download </span>

                                                </label>
                                            </div>
                                        </div> */}
                                        <button className='bg-orange-500 px-2 py-2 rounded-md text-sm flex items-center gap-2 text-white' onClick={getGroupExport}><TbFileExport className='text-md' /> Export Data</button>
                                        <button className='bg-[#393c3d] px-2 py-2 rounded-md text-sm flex items-center gap-2 text-white' onClick={sendGroupMail}><MdOutlineEmail className='text-md' /> Send Mail</button>
                                    </div>


                                </div>

                            </div>


                            {showDiv ? <div className='w-full border border-1 mt-4 rounded-lg p-3 mb-4'>
                                <div className="flex items-center justify-end">
                                    <button className='bg-orange-500 text-sm px-2 py-1 flex items-center gap-1 rounded-md text-white' onClick={handleCloseModal}><MdClear />  Close</button>
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-2'>
                                    {requestGroupList?.length ? requestGroupList?.map((ele) => {
                                        return (<><div className="shwoing-requeted">
                                            <h4 className='mb-4 mt-4 text-xs'>Requested By : <span className='bg-orange-500 px-2 py-1 pb-1 text-sm rounded-md ml-2 text-white'>{ele?.requestedBy}</span></h4>

                                            <table className='w-full'>
                                                <thead className='border border-gray-200'>
                                                    <tr className='border border-gray-200'>
                                                        <th className='border border-gray-200 text-left p-2'>Name</th>
                                                        <th className='border border-gray-200 text-left p-2'>Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ele?.attendeesDetails?.map((element) => {
                                                        return (<tr>
                                                            <td className='border border-gray-200 p-2 capitalize'>{element?.fullName}</td>
                                                            <td className='border border-gray-200 p-2'>{element?.email}</td>
                                                        </tr>)

                                                    })}
                                                </tbody>
                                            </table>




                                        </div></>)


                                    }) : "No Request Available"}
                                </div>

                            </div> : ""}

                            <div className='grid grid-cols-12 gap-x-4'>
                                <div className={`${openHistoryData ? "col-span-12 md:col-span-7" : "col-span-12 md:col-span-12"}`} >
                                    <div className='grid grid-cols-1 lg:grid-cols-2  gap-2'>
                                        {groupList?.map((ele, index) => {
                                            return (
                                                <>

                                                    <div className='brouplists border border-1 rounded-lg shadow-lg p-3 mb-3'>


                                                        <div className='flex items-center justify-between border-b border-1 pb-2 mb-2'>
                                                            <div className='flex items-center gap-1'>
                                                                {/* { ele?.attendees ?  <input type="checkbox" onChange={(e) => handleSelectAllClick(e, ele?.attendees)} /> :""}  */}
                                                                {ele?.groupNo === "Not_interested" ? <h2 className='font-bold text-[#2b2b2b] text-lg'> Not Interested Members</h2> : <h2 className='font-bold text-[#2b2b2b] text-lg'> {`Group ${index + 1}`}
                                                                    {/* {ele?._id?.ConnectMeetId} */}

                                                                </h2>}
                                                            </div>
                                                            {/* {ele?.isRandomGroup === false && ele?.attendees?.length < 0 ? <div >
                                                                <BsTrash3 onClick={() => handleDeleteIcon(ele)} />

                                                            </div> : ""}  */}

                                                            {ele?.isRandomGroup === false && Array.isArray(ele?.attendees) && ele.attendees.length >= 0 ? (
                                                                <div>
                                                                    <BsTrash3 onClick={() => handleDeleteIcon(ele)} />
                                                                </div>
                                                            ) : null}

                                                            {/* {ele?.isUnique === true ? <span className='bg-orange-500 p-2 text-xs  mb-0 text-white rounded-md inline-flex'>New Pair</span> : ""} */}
                                                        </div>
                                                        <p className=''>{ele?.attendees?.map((element) => {
                                                            return (
                                                                <div className='card_inners border-b  last:border-0  items-center flex  flex-wrap gap-y-2 items-center justify-between  p-2'>
                                                                    <div className='flex items-start gap-1'>
                                                                        {/* <input type="radio" className='mt-1' onChange={(e) => handleCheckboxClick(e, element?.attendeesDetails?.email)} /> */}
                                                                        {element?.attendeesDetails?.email && ele?.groupNo != "Not_interested" ? <input
                                                                            type="radio"
                                                                            className='mt-1 cursor-pointer'
                                                                            onChange={(e) => handleCheckboxClick(e, element?.attendeesDetails?.loginId, element?.attendeesDetails?.fullName)}
                                                                            checked={selectedAttendee === element?.attendeesDetails?.loginId}
                                                                        /> : ""}
                                                                        <div>
                                                                            {element?.attendeesDetails?.fullName ? <p className='flex items-center mb-2 gap-1 text-[#75757A] capitalize text-[14px] '><LuUser2 className='text-black !text-[16px]' />{element?.attendeesDetails?.fullName}</p> : ""}
                                                                            {element?.attendeesDetails?.email ? <p className='flex items-center gap-1 text-[#75757A]  text-[14px]'><MdOutlineEmail className='text-black !text-[16px]' />{element?.attendeesDetails?.email}</p> : ""}

                                                                        </div>
                                                                    </div>
                                                                    {element?.attendeesDetails?.fullName || element?.attendeesDetails?.email ?
                                                                        <div>
                                                                            {ele?.groupNo != "Not_interested" ? <div className='rounded-md shdaow-md bg-orange-400 focus:outline-none cursor-pointer  px-2 pb-1 '>
                                                                                <select className=" bg-transparent focus:outline-none cursor-pointer text-[14px]  text-white" onChange={(e) => handleSelectValue(e, element)}>
                                                                                    <option value="" disabled selected>Move to</option>
                                                                                    {/* {groupList?.filter((data) => data?._id?.ConnectMeetId !== ele?._id?.ConnectMeetId)?.map((data ,ind) => (
                                                                                        <option className='text-md text-black' key={data?.groupNo} value={data?._id?.ConnectMeetId}>Group
                                                                                         {data?._id?.ConnectMeetId  } 
                                                                                      
                                                                                        </option>
                                                                                    ))} */}
                                                                                    {transformedArray?.map((data, index) => (

                                                                                        data._id !== ele._id?.ConnectMeetId && (
                                                                                            <option key={index} className='text-md text-black' value={data?._id}>
                                                                                                Group {data.name}
                                                                                            </option>
                                                                                        )
                                                                                    ))}

                                                                                </select>

                                                                            </div> : ""}

                                                                        </div>

                                                                        : ""}

                                                                </div>

                                                            )
                                                        })}</p>
                                                    </div>
                                                </>
                                            )

                                        })}
                                    </div>

                                </div>


                                {openHistoryData ? <div className='col-span-12 md:col-span-5'>
                                    <div className='histroy_showinf p-4 rounded-md border border-gray-300 '>

                                        <div className='flex items-center justify-between mb-4'>

                                            <h6 className='text-black text-xl flex items-center gap-2 '> <GoHistory /> <span className='capitalize'>{selectedName}</span> History </h6>
                                            <button className='cursor-pointer ' onClick={handleclose}><Tooltip title="Close" placement='top' ><span><IoMdClose /></span></Tooltip></button>
                                        </div>



                                        {historyData?.length ? <div className='grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-4'>

                                            {historyData?.map((ele) => {
                                                return (<div className="w-full ">
                                                    <div className=" border border-gray-200 rounded-lg">
                                                        <Disclosure >
                                                            {({ open }) => (
                                                                <>
                                                                    <Disclosure.Button className="flex w-full items-center cursor-pointer justify-between rounded-lg bg-orange-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-orange-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                                                        <div className=' '>

                                                                            <div className='flex flex-col gap-y-1'>
                                                                                <h4 className='text-sm font-semibold text-[#2b2b2b] break-all capitalize'>{ele?.eventsDetails?.[0]?.title} </h4>
                                                                                <h4 className='text-xs font-normal text-[#2b2b2b] break-all capitalize'>{ele?.eventsDetails?.[0]?.date ? datepipeModel.datetime(ele?.eventsDetails?.[0]?.date) : ""}</h4>
                                                                                {/* <p className='text-xs text-gray-500'>{ele?.ConnectMeetId}</p> */}
                                                                            </div>
                                                                        </div>
                                                                        <ChevronUpIcon
                                                                            className={`${open ? 'rotate-180 transform' : ''
                                                                                } h-5 w-5 text-black`}
                                                                        />
                                                                    </Disclosure.Button>
                                                                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                                                                        {ele?.membersDetails?.map((element) => {
                                                                            return (
                                                                                <div className=''>
                                                                                    <p className='flex items-center mb-2 gap-1 text-[#75757A] capitalize text-[12px] xl:text-[14px] break-all'><LuUser2 className='text-black !text-[16px]' />{element?.fullName}</p>
                                                                                    <p className='flex items-center mb-2 gap-1 text-[#75757A] text-[12px] xl:text-[14px] break-all '><MdOutlineEmail className='text-black !text-[16px]' />{element?.email}</p>
                                                                                </div>
                                                                            )
                                                                        })}

                                                                    </Disclosure.Panel>
                                                                </>
                                                            )}
                                                        </Disclosure>



                                                        <div></div>
                                                    </div>
                                                </div>)
                                            })}
                                        </div> : `No History Available For Past ${localStorage.getItem("connectMeetHistory") || 6} Months`}



                                    </div>

                                </div> : ""}




                            </div>


                        </div>

                        {/* {Object.entries(eventDetail).map(([group, items] :any) => (
                <div key={group}>
                    <p>{group}</p>
                    {items?.map((item) => (
                        <div key={item.id} style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <p>{item.fullName}</p>
                            <p>{item.email}</p>
                            <select className="form-select" onChange={handleSelectValue}>
                                <option value="" disabled selected>Move to</option>
                                <option value="group1">1</option>
                                <option value="group2">2</option>
                                <option value="group3">3</option>
                                <option value="group4">4</option>
                            </select>
                        </div>
                    ))}
                </div>
            ))} */}
                        {requestGroup ? <>
                            <Modal

                                title="Request Group"
                                body={<>
                                    <AddNewGroup dataaa={receivedStateData?.dataa} setRequestGroup={setRequestGroup} getGroupPair={getGroupPair} receivedStateData={receivedStateData} />
                                </>}
                                result={e => {
                                    setRequestGroup(false)
                                }}
                            />
                        </> : <></>}
                        {historyModal ? <>
                            <Modal

                                title=" History"
                                body={<>
                                    <GroupHistory data={historyData} />
                                </>}
                                result={e => {
                                    setHistoryModal(false)
                                }}
                            />
                        </> : <></>}
                    </div>
                </div>

            </PageLayout>
        </>


    )
}

export default Groups