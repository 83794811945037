import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Table from "../Table";
import { useNavigate, useParams, Link } from "react-router-dom";
import { BsTrash3 } from "react-icons/bs";
import SelectDropdown from "../common/SelectDropdown";
import { Tooltip } from "antd";
import methodModel from "../../methods/methods";
import { FiPlus } from "react-icons/fi";
import AddAttendee from "../../Pages/Events/AddAttendee";
import rolesModel from "../../models/roles.model";
import { useSelector } from "react-redux";
import datepipeModel from "../../models/datepipemodel";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import "./style.scss";
import { toast } from "react-toastify";
import { encryptId } from "../common/Encryption/encryption";
import environment from "../../environment";
export default function Members({ eventDetail = "", eventId = "", getDetailApi }: any) {

  const [dataa, setDataa] = useState([]);
  const [isGroupGenerated, setIsGroupGenerated] = useState();
  const [data, setData] = useState([]);
  const [selectAllData ,setselectAllData] = useState([])
  const [loading, setLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const [tab, setTab] = useState("list");
  const [role, setRole] = useState();
  const [selectedCount, setSelectedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  //  A State here which will hold the all selected data  of Attendances
  const [SelectedAttendances, setSelectedAttendances]: any = useState([]);
console.log(SelectedAttendances ,"SelectedAttendancesSelectedAttendancesSelectedAttendances")
  const user = useSelector((state: any) => state.user);
  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    search: "",
    eventId: eventId,
    sortBy: "",
    key: "",
    sorder: "",
  });
  const history = useNavigate();
  const attendPremit = (row: any) => {
    let value = false;
    if (row?.addedBy === user._id || eventDetail?.addedBy === user._id)
      value = true;
    if (role == "meetManager" || role == "assistant") value = true;
    return value;
  };
  const getDetail = () => {
    loader(true);
    ApiClient.get("api/event/details", {
      id: eventId,
      email: user?.email,
    }).then((res) => {
      loader(false);
      if (res.success) {
        setData(res.data);
      }
    });
  };
  const deletePremit = (row: any) => {
    let value = false;
    if (row?.addedBy === user._id || eventDetail?.addedBy === user._id)
      value = true;
    if (role == "assistant") value = true;
    return value;
  };

  const columns = [
    {
      key: "fullName",
      name: "Name",
      render: (row: any) => {
        return <>{row?.memberDetails?.fullName}</>;
      },
    },
    {
      key: "email",
      name: "Email",
      render: (row: any) => {
        return <>{row?.memberDetails?.email}</>;
      },
    },
    {
      key: "isConnectedMeating",
      name: "Connect Meet",
      render: (row: any) => {
        return (
          <>
            <label
              className={`inline-flex items-center cursor-pointer ${attendPremit(row) ? "" : "opacity-50"
                }`}
            >
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                disabled={attendPremit(row) ? false : true}
                onChange={(e) => connectToggle(row)}
                checked={row.isConnectedMeating}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
            </label>
          </>
        );
      },
    },
    {
      key: "isPresent",
      name: "Present",
      render: (row: any) => {
        return (
          <>
            <label
              className={`inline-flex items-center cursor-pointer ${attendPremit(row) ? "" : "opacity-50"
                }`}
            >
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                disabled={attendPremit(row) ? false : true}
                onChange={(e) => attend(row)}
                checked={row.isPresent}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
            </label>
          </>
        );
      },
    },
    {
      key: "role",
      name: "Role",
      render: (row: any) => {
        return (
          <>
            <SelectDropdown
              placeholder="Select Role"
              intialValue={row.attendeeRole}
              disabled={eventDetail?.addedBy === user._id ? false : true}
              options={rolesModel.list}
              result={(e: any) => {
                roleUpdate(row, e.value);
              }}
            />
          </>
        );
      },
    },
    {
      key: "action",
      name: "Action",
      render: (itm: any) => {
        return (
          <>
            <div className="flex items-center justify-start gap-1.5">

              {deletePremit(itm) ? (
                <>
                  <Tooltip placement="top" title="Delete">
                    {" "}
                    <span
                      onClick={() => deleteItem(itm.id)}
                      className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl"
                    >
                      <BsTrash3 />
                    </span>{" "}
                  </Tooltip>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const connectToggle = (row: any) => {
    let isConnectedMeating = row.isConnectedMeating ? false : true;
    loader(true);
    ApiClient.put("api/attendees/update", {
      id: row.id,
      isConnectedMeating: isConnectedMeating,
    }).then((res) => {
      if (res.success) {
        getData();
      }
      loader(false);
    });
  };

  const attend = (row: any) => {
    let isPresent = row.isPresent ? false : true;
    loader(true);
    ApiClient.put("api/attendees/update", {
      id: row.id,
      isPresent: isPresent,
    }).then((res) => {
      if (res.success) {
        getData();
      }
      loader(false);
    });
  };
  {

  }
  const roleUpdate = (row: any, role: any) => {
    loader(true);
    ApiClient.put("api/attendees/update", {
      id: row.id,
      attendeeRole: role,
    }).then((res) => {
      if (res.success) {
        getData();
      }
      loader(false);
    });
  };

  const getData = (p = {}) => {
    let f = { ...filters, ...p };
    setLoader(true);

    ApiClient.get("api/attendees/list", f).then((res) => {
      if (res.success) {
        setIsGroupGenerated(res?.isGroupGenerated);
        setDataa(res.data);
        setTotal(res.total);
      }
      setLoader(false);
    });
  };
  const getDataSelect = (p = {}) => {
    let payload={
      eventId: eventId,
    }
    setLoader(true);

    ApiClient.get("api/attendees/list",payload).then((res) => {
      if (res.success) {
        setselectAllData(res?.data)
      
        
      }
      setLoader(false);
    });
  };
  const getRole = () => {
    let f = {
      search: user.email,
      count: 1,
    };
    ApiClient.get("api/attendees/list", f).then((res) => {
      if (res.success) {
        let data = res.data?.[0];
        setRole(data?.attendeeRole || "member");
      }
    });
  };

  useEffect(() => {
    getData();
    getRole();
    // getDetail();
    getDetailApi()
    getDataSelect()
  }, []);

  const pageChange = (e: any) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };

  const sorting = (key: any) => {
    let sorder = "asc";
    if (filters.key == key) {
      if (filters.sorder == "asc") {
        sorder = "desc";
      } else {
        sorder = "asc";
      }
    }

    let sortBy = `${key} ${sorder}`;
    setFilter({ ...filters, sortBy, key, sorder });
    getData({ sortBy, key, sorder });
  };

  const clear = () => {
    let f = {
      search: "",
      page: 1,
    };
    setFilter({ ...filters, ...f });
    getData({ ...f });
  };

  const deleteItem = (id: any) => {
    if (window.confirm("Do you want to delete this")) {
      loader(true);
      ApiClient.delete("api/attendees", { id: id }).then((res) => {
        if (res.success) {
          clear(); 
        }
        loader(false);
      });
    }
  };
  const handleOpenModal = () => {
    //   history(`/groupsDetail/${eventDetail?._id}`);
    const id = eventDetail?._id;

    const stateData = {
      eventDetail: "",
      dataa: { data },
      eventId: { id },
      groupId:user?.groupId,
      attendeesGroup: "",
    };
    history(`/groupsDetail/${encryptId(eventDetail?._id)}`, {
      state: stateData,
    });
  };
  const markAttendance = () => {
    loader(true)
    ApiClient.post('api/sentEmail', { eventId: eventId }).then(res => {
      loader(false)
      if (res.success) {
        // toast.success(res.message)
        // getDetail()
        getDetailApi()
      }
    })
  }

  const handleMarkAttedance = () => {
    loader(true)
    ApiClient.post(`api/mark/attandance`, { data: SelectedAttendances }).then(
      (res) => {
        if (res.success) {
          toast.success(res?.message);
          getRole();
          loader(false)
          getData();
          setSelectedAttendances([])
          if (eventDetail?.isMarkAttendance === false) {
            markAttendance()

          }
        }
      }
    );

    // getDetail();

  };
  const id = eventDetail?._id;
  // const [payload, setPayload] = useState([{ email: "", loginId: "", eventId: "" }])


  const ListHtml = ({ row }: any) => {

    // const [isChecked, setIsChecked] = useState();
    interface PayloadItem {
      email: string;
      loginId: string;
      eventId: string;
    }
    const [array, setarray] = useState([]);
    const id = eventDetail?._id;
    const [payload, setPayload] = useState<PayloadItem[]>([]);
    const [newarray, setnewarray] = useState<PayloadItem[]>([]);
    useEffect(() => {
      const totalPending = dataa.filter((row: any) => row.isPresent ).length;
      setTotalCount(totalPending);
    }, []);
    const handleCheckboxChange = (row: any) => {
      const email = row.email === user?.email || row.type === "guest" ? row.email : row.memberDetails?.email || "";
      const loginId = row.email === user?.email ? row.addedByDetail?.loginId : row.memberDetails?.loginId || "";
      const eventId = eventDetail?._id || "";

      let newSelectedAttendances = [...SelectedAttendances];
      const index = newSelectedAttendances.findIndex(item => item.loginId === loginId);

      if (index > -1) {
        newSelectedAttendances.splice(index, 1);
      } else {
        newSelectedAttendances.push({ email, loginId, eventId });
      }

      setSelectedAttendances(newSelectedAttendances);

      // Update selected count
      setSelectedCount(newSelectedAttendances.length);

      // // Calculate pending count
      // const totalPending = dataa.filter((row: any) => row.meetConfirm === "Yes" && !row.isPresent).length;
      // setTotalCount(totalPending);

      setSelectAll(newSelectedAttendances.length === dataa.length); // Update selectAll based on current selection
    };

    // const handleCheckboxChange = (row: any) => {

    //   const email =
    //     row?.email == user?.email || row?.type == "guest"
    //       ? row?.email
    //       : row.memberDetails?.email || "";
    //   const loginId: any =
    //     row?.email == user?.email
    //       ? row?.addedByDetail?.loginId
    //       : row.memberDetails?.loginId || "";
    //   const eventId = eventDetail?._id || "";
    //   let PreviousData: any = SelectedAttendances;
    //   const Indesx = PreviousData?.findIndex(
    //     (item: any) => item?.loginId == loginId
    //   );
    //   if (!Indesx) {
    //     PreviousData = PreviousData.filter(
    //       (item: any) => item?.loginId != loginId
    //     );
    //   } else {
    //     const Payload = { email, loginId, eventId };
    //     PreviousData.push(Payload);
    //   }

    //   setSelectedAttendances([...PreviousData]);
    // const itemIndex = payload.findIndex(item => item.email === email);

    // if (itemIndex > -1) {

    //     const newPayload = payload.filter(item => item.email !== email);
    //     setPayload([...newPayload]);
    // } else {

    //     const newPayload = [...payload];

    //     newPayload?.push( { email, loginId, eventId })
    //     setPayload([...newPayload]);
    //     console.log(newPayload , "newPayloadnewPayloadnewPayload")
    // }
    // };

    // const isChecked =
    //   SelectedAttendances.some(
    //     (item: any) => item.email == row?.email
    //   )

    const isChecked = !row.isPresent && row.connectMeet !== "not_interested" && (selectAll || SelectedAttendances.some((item: any) => item.email === row?.email));
    // const isChecked = selectAll || SelectedAttendances.some((item: any) => item.email === row?.email);
    return (
      <>
        <ul className="w-[100%]  md:w-[49%] lg:w-[33.33%] inline-block pr-2">
          <li className={`divide-y divide-gray-200 w-full  pb-2  `}>
            <div
              className={`main_innerss bg-white ${row.isPresent ? "bg-green-200 " : ""
                } rounded-md border border-neutral-300 p-4`}
            >
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-shrink-0">

                  <img
                    src={methodModel.userImg(row?.memberDetails?.image)}
                    className="h-12 w-12 rounded-md mx-auto object-cover"
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white capitalize ">
                    {row.fullName || row.memberDetails?.fullName}{" "}
                    {row.memberDetails?.fullName ? "" : ""}
                    <p className="text-red-500">{row?.connectMeet == "not_interested" ? "Not Attending" : ""}</p>

                  </p>
                  <p className="text-xs"> {row?.type == "Group Leader" ? "Group Leader" : row?.type == "guest" ? "Guest" : ""}</p>
                  <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                    {row.memberDetails?.email || row.email}
                  </p>
                </div>
              </div>
              <div className="border-t border-neutral-300 mt-3 pt-3">
                <div className="text-sm flex items-center justify-between mb-2">
                  <p className="text-black font-medium"> RSVP'd </p>

                  <p className="font-semibold">
                    {" "}
                    {row?.meetConfirm === "Yes" ? "Yes" : "No"}
                  </p>
                </div>

                <div className="text-sm flex items-center justify-between mb-2">
                  <p className="text-black font-medium"> Checked In </p>

                  <p className="font-semibold">
                    {" "}
                    {row?.isPresent ? "Yes" : "No"}
                  </p>

                  {row?.meetConfirm === "Yes" &&
                    // user?.customerRole?._id == environment?.glRoleId ? (
                    <label className="switch">
                      <input
                        disabled={row?.isPresent == true || row?.connectMeet == "not_interested"}
                        checked={isChecked || row.isPresent}
                        type="checkbox"
                        onChange={() => handleCheckboxChange(row)}
                      />
                      <span className="slider round"></span>
                    </label>
                    // ) : (
                    //   ""
                    // )
                  }
                </div>

                <div className="text-sm flex items-center justify-between mb-2">
                  <p className="text-black font-medium"> ConnectMeet </p>

                  <p className="font-semibold">
                    {" "}
                    {row?.connectMeet === "connect_with_members"
                      ? "Available"
                      : row?.connectMeet === "not_interested"
                        ? "Unavailable"
                        : row?.connectMeet === "system_generate"
                          ? "Available"
                          : "Available"}
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </>
    );
  };
  const handleSelectAll = () => {
    const newSelectedAttendances = selectAll
      ? []
      : selectAllData.filter((row: any) =>

        row.meetConfirm === "Yes" && !row?.isPresent && row?.connectMeet != "not_interested"
      ).map((row: any) => {
   
        const email = row.email === user?.email || row.type === "guest" ? row.email : row.memberDetails?.email || "";
        const loginId = row.email === user?.email ? row.addedByDetail?.loginId : row.memberDetails?.loginId || "";
        const eventId = eventDetail?._id || "";
        return { email, loginId, eventId };
      });

    setSelectedAttendances(newSelectedAttendances);
    setSelectAll(!selectAll);
    // Update selected count
    setSelectedCount(newSelectedAttendances.length);
    //  // Calculate pending count
    //  const totalPending = dataa.filter((row: any) => row.meetConfirm === "Yes" && !row.isPresent).length;
    //  setTotalCount(totalPending);
  };

  const pending = totalCount - selectedCount


  return (
    <>
      <div>
        {tab == "list" ? (
          <>
            {eventDetail?.addedBy == user._id ||
              role == "meetManager" ||
              role == "assistant" ? (
              <>
                <button
                  onClick={() => setTab("add")}
                  className="bg-primary leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg gap-2"
                >
                  <FiPlus className="text-xl text-white" /> Add Member
                </button>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <button
              onClick={() => {
                setTab("list");
              }}
              className="bg-primary leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg gap-2"
            >
              Back
            </button>
          </>
        )}
      </div>

      {tab == "add" ? (
        <>
          <AddAttendee
            eventId={eventId}
            eventDetail={eventDetail}
            result={(e: any) => {
              if (e.event == "submit") {
                clear();
                setTab("list");
              }
            }}
          />
        </>
      ) : (
        <>
          {loading ? (
            <>
              <div className="text-center">Loading...</div>
            </>
          ) : (
            <>
              <div className="flex items-center justify-between gap-2 ">

                <div className="">
                  <h4 className="text-xl capitalize font-semibold">
                    {eventDetail?.title}
                  </h4>
                  <p className="text-md mt-1 font-medium text-black-800">
                    {datepipeModel.datetime(eventDetail?.date)}
                  </p>
                
                </div>
                <div className="flex gap-4 items-center">
                  {/* <button
                    className="bg-primary text-white px-3 py-3 rounded-xl"
                    onClick={handleMarkAttedance}
                  >
                    Mark Attendance
                  </button> */}
                 
                  <button
                    className={`bg-primary text-white px-3 py-3 rounded-xl ${SelectedAttendances.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleMarkAttedance}
                    disabled={SelectedAttendances.length === 0} // Disable if no checkboxes are selected
                  >
                    Mark Attendance
                  </button>

                  {isGroupGenerated ? (
                    <div className="flex justify-end flex-grow">
                      <button
                        onClick={handleOpenModal}
                        className="bg-[#46454E] inline-flex  py-3 px-3 flex items-center justify-center gap-x-2 text-white shadow hover:shadow-lg rounded-xl"
                      >
                        <AiOutlineUsergroupAdd />
                        See All Groups
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between gap-2 bg-gray-50 p-4 rounded-lg">
                  {/* <div className="flex gap-4">
                      <div className="flex items-center gap-4">
                        Total <span className="bg-primary text-white p-2 rounded-md px-4 py-1">{totalCount}</span>
                      </div>
                      <div className="flex items-center gap-4">
                      Selected <span className="bg-gray-200 p-2 rounded-md px-4 py-1">{selectedCount}</span>
                      </div>
                      <div className="flex items-center gap-4">
                      Pending <span className="bg-gray-200 p-2 rounded-md px-4 py-1">{pending ? pending : 0}</span>
                      </div>
                    
                  </div> */}
                  <div className="flex items-center ">
                    <label className="flex cursor-pointer gap-0.5 items-center">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                        className=" h-4 w-4"
                      />
                      <span>Select All</span>
                    </label>
                  </div>
              </div>

              <Table
                data={dataa}
                columns={columns}
                page={filters.page}
                count={filters.count}
                total={total}
                theme="list"
                ListHtml={ListHtml}
                result={(e) => {
                  if (e.event == "page") pageChange(e.value);
                  if (e.event == "sort") sorting(e.value);
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
}
