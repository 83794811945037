import React, { useEffect, useState } from 'react';
import Layout from '../../components/global/layout'
import PageLayout from '../../components/global/PageLayout'
import ApiClient from '../../methods/api/apiClient'
import loader from '../../methods/loader'
import { Tooltip } from "antd";
import datepipeModel from '../../models/datepipemodel';
import { useSelector } from 'react-redux';
import Table from "../../components/Table";
import environment from '../../environment';
import { useNavigate } from 'react-router-dom';
import { IoEyeOutline } from 'react-icons/io5';
import { encryptId } from '../../components/common/Encryption/encryption';
const GroupRequest = () => {
    const [filters, setFilter] = useState({ page: 1, count: 10, search: '' })
    const [total, setTotal] = useState(0)
    const searchState = {data:''}
    const [data, setData] = useState([])
    const user = useSelector((state) => state.user);
    const history = useNavigate()
    const columns = [
        {
            key: 'Event Name', name: 'Event Name', sort: true,
            render: (row) => {
                return <span className='capitalize'>{row?.eventDetails?.title}</span>
            }
        },
        {
            key: 'Date', name: 'Date', sort: true,
            render: (row) => {
                return <span className='capitalize'>{datepipeModel.date(row?.eventDetails?.createdAt) }</span>
            }
        },
        {
            key: 'action', name: 'Action',
            render: (row) => {
                return <>
                    <div className="flex items-center justify-start gap-1.5">
                        <Tooltip placement="top" title="View">
                            <a className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-md bg-[#fff] w-9 h-9 text-[#E92531] flex items-center justify-center text-md" onClick={e => view(row?.eventDetails?._id)}>
                            <IoEyeOutline />

                            </a>
                        </Tooltip>
                        
                    </div>
                </>
            }
        },
    ]
    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }
    const count = (e) => {
        setFilter({ ...filters, count:e })
        getData({ ...filters ,count: e })
        }
    const changestatus = (e) => {
        setFilter({ ...filters, status: e, page: 1 })
        getData({ status: e, page: 1 })
    }
    const getData = (p = {}) => {
        loader(true)
      
     
        ApiClient.get(`api/group-request/events/list?groupId=${user?.groupId?._id}`).then(res => {
            if (res.success) {
                setData(res?.data)
                setTotal(res.total)
            }
            loader(false)
        })
    }
    const view = (idd) => {
        let url=`/connectMeetRequest/${encryptId(idd)}`
        history(url)
    }
    const sorting = (key) => {
        let sorder = 'asc'
        if (filters.key == key) {
            if (filters.sorder == 'asc') {
                sorder = 'desc'
            } else {
                sorder = 'asc'
            }
        }

        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder })
        getData({ sortBy, key, sorder })
    }
    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [])
    return (
        <>
            <Layout>
            <div className='mb-6'>
                    <h3 className="text-2xl font-semibold text-[#111827]"> ConnectMeet Request</h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your ConnectMeet Request</p>
                </div>


                <Table
                    className=''
                    data={data}
                    columns={columns}
                    page={filters.page}
                    count={filters.count}
                    total={total}
                    nodata="No ConnectMeet Request yet"
                    result={(e) => {
                        if (e.event == 'page') pageChange(e.value)
                        if (e.event == 'sort') sorting(e.value)
                        if (e.event == 'count') count(e.value)
                    }}
                />
            </Layout>
        </>
    )
}

export default GroupRequest