import { useEffect, useState } from "react";
import FormControl from "../../components/common/FormControl";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import environment from "../../environment";
import { useSelector } from "react-redux";
import { FiPlus } from "react-icons/fi";
import { toast } from "react-toastify";
import pipeModel from "../../models/pipeModel";

export default function AddAttendee({
  id = "",
  eventId,
  guest = false,
  result = (e) => {},
  eventDetail,
}) {
  const user = useSelector((state) => state.user);
  const [submitted, setSubmitted] = useState(false);
  const [users, setUsers] = useState([]);
  const [members, setMember] = useState([
    { fullName: "", email: "", firstName: "", lastName: "" },
  ]);
  const [allMembersAdded, setAllMembersAdded] = useState(false);

  useEffect(() => {
    // Check if all members have been added
    setAllMembersAdded(members.length === users.length);
  }, [members, users]);
 
  // Function to handle form submission
  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     setSubmitted(true);
  //     // Your existing logic for form submission
  // };
  const handleAddAllMembers = () => {
    let updatedMembers = users.map((user) => ({
      memberId: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    }));
    setMember(updatedMembers);
    setAllMembersAdded(true); // Set allMembersAdded to true
  };

  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     setSubmitted(true);
  //     let method = 'post';
  //     let url = 'api/attendees';

  //     let value;
  //     if (members.length === users.length) {
  //         // All members selected, handle accordingly
  //         value = {
  //             data: members.map(itm => ({
  //                 ...itm,
  //                 eventId: eventId,
  //                 attendeeRole: 'member',
  //                 addedBy: user._id,
  //                 groupId: eventDetail?.groupId?._id || '',
  //                 isConnectedMeating: true,
  //                 fullName: itm.firstName + ' ' + itm.lastName
  //             }))
  //         };
  //     } else {
  //         // Handle normal selected members case
  //         value = {
  //             data: members.filter(itm => itm.memberId)
  //                 .map(itm => ({
  //                     ...itm,
  //                     eventId: eventId,
  //                     attendeeRole: 'member',
  //                     addedBy: user._id,
  //                     groupId: eventDetail?.groupId?._id || '',
  //                     isConnectedMeating: true,
  //                     fullName: itm.firstName + ' ' + itm.lastName
  //                 }))
  //         };
  //     }

  //     loader(true);
  //     ApiClient.allApi(url, value, method).then(res => {
  //         if (res.success) {
  //             result({ event: 'submit', value: res });
  //         }
  //         loader(false);
  //     });
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    let method = "post";
    let url;
    let value;

    if (guest) {
      // When guest is true, use the add/guest/attendee API endpoint
      url = "api/add/guest/attendee";
      value = {
       
          eventId: eventId,
          groupId: eventDetail?.groupId?._id || "",
          email: members[0].email,
          firstName:pipeModel.trimText(members[0]?.firstName),
          lastName:pipeModel.trimText(members[0]?.lastName),
          fullName: pipeModel.trimText(members[0]?.firstName) + " " + pipeModel.trimText(members[0].lastName),
          addedBy: user?.id
      
      };
      //   const guestDetails = members[0];

      //   value = {
      //     eventId: eventId,
      //     groupId: eventDetail?.groupId?._id || "",
      //     email: guestDetails.email,
      //     fullName: guestDetails.firstName + " " + guestDetails.lastName,
      //   };
    } else {
      // Original logic for members
      url = "api/attendees";

      if (members.length === users.length) {
        value = {
          data: members.map((itm) => ({
            ...itm,
            eventId: eventId,
            attendeeRole: "member",
            addedBy: user._id,
            groupId: eventDetail?.groupId?._id || "",
            isConnectedMeating: true,
            firstName:pipeModel.trimText(itm?.firstName),
            lastName:pipeModel.trimText(itm?.lastName),
            fullName: pipeModel.trimText(itm.firstName) + " " + pipeModel.trimText(itm.lastName),
          })),
        };
      } else {
        value = {
          data: members
            .filter((itm) => itm.memberId)
            .map((itm) => ({
              ...itm,
              eventId: eventId,
              attendeeRole: "member",
              addedBy: user._id,
              groupId: eventDetail?.groupId?._id || "",
              isConnectedMeating: true,
              fullName: pipeModel.trimText(itm.firstName) + " " + pipeModel.trimText(itm.lastName),
            })),
        };
      }
    }

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        result({ event: "submit", value: res });
        toast.success(res?.message);
      }
      loader(false);
    });
  };

  // Function to fetch users
  const getUsers = (p = {}) => {
    let f = {
      ...p,
      groupId: eventDetail?.groupId?._id || "",
      eventId: eventId,
      isInvited: false,
      isDeleted: false,
    };
    ApiClient.get("api/members/list", f).then((res) => {
      if (res.success) {
        setUsers(res.data);
      }
    });
  };

  // Function to get attendee list
  const getAttendeList = (p = {}) => {
    ApiClient.get(`api/attendees/list?eventId=${eventId}&joinRequest=`).then(
      (res) => {
        if (res.success) {
        
        }
      }
    );
  };

  // useEffect to fetch initial data
  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get("api/attendees/detail", { id }).then((res) => {
        if (res.success) {
          let value = res.data;
        }
        loader(false);
      });
    }
    getUsers();
    getAttendeList();
  }, [id]);

  // Function to add more members
  const addMore = () => {
    let arr = members;
    let payload = { fullName: "", email: "", lastName: "", firstName: "" };
    arr.push(payload);
    setMember([...arr]);
  };

  // Function to update member details
  const updateMember = (i, key = "", value = "") => {
    let arr = members;
    arr[i][key] = value;
    setMember([...arr]);
  };

  // Function to update all member details
  const updateMemberAll = (i, values) => {
    let arr = members;
    arr[i] = {
      ...values,
      firstName: values.firstName || "",
      lastName: values.lastName || "",
    };
    setMember([...arr]);
  };

  // Function to remove a member
  const removeMember = (i) => {
    let arr = members;
    arr = arr.filter((itm, ind) => ind !== i);
    setMember([...arr]);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="">
        {members.map((itm, i) => (
          <div key={i} className="flex items-end gap-3 mt-4  pb-2">
            {guest ? (
              <div className="">
                <FormControl
                  type="email"
                  label="Email"
                  value={itm.email}
                  onChange={(e) => updateMember(i, "email", e)}
                  required
                />
              </div>
            ) : (
              <div className="flex-grow w-96  ">
                <FormControl
                  type="select"
                  displayValue="email"
                  valueType="object"
                  placeholder="Select Member"
                  label="Member"
                  theme="search"
                  className="text-lowercase"
                  value={itm.memberId}
                  options={
                    users.length > 0
                      ? [
                          { id: "all", email: "All" },
                          ...users.filter(
                            (user) =>
                              !members.some(
                                (itm) => itm.email === user.email
                              ) || itm.email === user.email
                          ),
                        ]
                      : []
                  }
                  onChange={(e) => {
                    if (e?.id === "all") {
                      // Handle adding all members functionality
                      handleAddAllMembers();
                    } else if (e?.id) {
                      updateMemberAll(i, {
                        ...itm,
                        memberId: e.id,
                        email: e.email,
                        firstName: e.firstName,
                        lastName: e.lastName,
                      });
                    } else {
                      updateMemberAll(i, {
                        ...itm,
                        memberId: "",
                        email: "",
                        firstName: "",
                        lastName: "",
                      });
                    }
                  }}
                  required={true}
                  error={submitted && !itm.memberId ? "Member is required" : ""}
                />
              </div>
            )}
            <div className="">
              <label>First Name</label>
              <input
                value={itm.firstName}
                onChange={(e) => updateMember(i, "firstName", e.target.value)}
                required
                onKeyPress={(e) => {
                  if (e.key === ' ') e.preventDefault(); // Prevent space
              }}
                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 capitalize"
                type="text"
              />
            </div>
            <div className="">
              <label> Last Name</label>
              <input
                value={itm.lastName}
                onChange={(e) => updateMember(i, "lastName",e.target.value)}
                required
                onKeyPress={(e) => {
                  if (e.key === ' ') e.preventDefault(); // Prevent space
              }}
                className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 capitalize"
                type="text"
              />
            </div>

            <div className="">
              {/* {members.length > 1&& members.length!=(i+1)? (
                                <button type="button" onClick={() => removeMember(i)} className="text-black cursor-pointer flex items-center gap-2 focus:ring-0 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm py-2.5 text-center">
                                    <span className="material-symbols-outlined">delete</span>

                                </button>
                            ) : null} */}
              {members.length > 1 ? (
                <button
                  type="button"
                  onClick={() => removeMember(i)}
                  className="text-black cursor-pointer flex items-center gap-2 focus:ring-0 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm py-2.5 text-center"
                >
                  <span className="material-symbols-outlined">delete</span>
                </button>
              ) : (
                ""
              )}
            </div>

            {!guest && !allMembersAdded && members.length == i + 1 && (
              <div className="text-right mt-3">
                <button
                  type="button"
                  onClick={addMore}
                  className="text-white bg-orange-600 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  <FiPlus className="text-xl" />
                </button>
              </div>
            )}
          </div>
        ))}

        <div className="text-right mt-3">
          <button
            type="submit"
            className="text-white  bg-orange-600 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
