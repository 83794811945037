import React, { useState, useEffect } from 'react';
import Layout from '../components/global/layout';
import ApiClient from "../methods/api/apiClient";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import datepipeModel from '../models/datepipemodel';
import loader from '../methods/loader';
import Table from "../components/Table";
import { IoMdCloudDownload, IoMdCloudUpload } from 'react-icons/io';
import environment from '../environment';
import axios from 'axios';
import Modal from '../components/common/Modal';
import { FaUser } from 'react-icons/fa6';
const History = () => {
    const user = useSelector((state) => state.user);
    const [loaging, setLoader] = useState(true)
    const [loading, setLoading] = useState(true);
    const [importedData, setImportedData] = useState([]);
    const [connectMeetData, setConnectMeetData] = useState([]);
    const [total, setTotal] = useState(0)
    const [type, setType] = useState("import")
    const [activeTab, setActiveTab] = useState('connectMeet');
    const [filters, setFilters] = useState({ page: 1, count: 10, search: '' });
    const [totalHistory, settotalHistory] = useState()
    const [historyModal, setHistoryModal] = useState(false)
    const [filtershistory, setFiltershistory] = useState({ page: 1, count: 10, search: '', loginId: user?.loginId });
    const columnsImported = [
        {
            key: 'fullName', name: 'Name', sort: true,
            render: (row) => {
                return <p className='capitalize'>{row?.membersDetails?.fullName ? row?.membersDetails?.fullName : row?.attendeeDetails?.fullName}</p>
            }
        },
        {
            key: 'email', name: 'Email',
            render: (row) => {
                return <>{row?.membersDetails?.email ? row?.membersDetails?.email : row?.attendeeDetails?.email}</>
            }
        },
        {
            key: 'connectMeetDate', name: 'ConnectMeet Date',
            render: (row) => {
                return <>{datepipeModel.datetime(row?.eventsDetails?.date ? row?.eventsDetails?.date : "")}</>
            }
        },
        {
            key: 'connectMeetId', name: 'ConnectMeet Id',
            render: (row) => {
                return <>{row?.ConnectMeetId}</>
            }
        },
        {
            key: 'eventName', name: 'Event Name ', sort: true,
            render: (row) => {
                return <p className='capitalize'>{row?.eventsDetails?.title}</p>
            }
        },
        {
            key: 'eventDate', name: 'Event Date ',
            render: (row) => {
                return <>{row?.eventsDetails?.createdAt ? datepipeModel.datetime(row?.eventsDetails?.createdAt) : ""}</>
            }
        },
        // Other column definitions...
    ];
    const [dataName, setDataName] = useState()
    const handleSeeMore = (ele) => {
        setHistoryModal(true)
       
        setDataName(ele)
    }
    const columnsConnectMeet = [
        {
            key: 'connectMeetId', name: 'ConnectMeet Id',
            render: (row) => {
                return <>{row?.ConnectMeetId}</>
            }
        },
        {
            key: 'eventName', name: 'Event Name ', sort: true,
            render: (row) => {
                return <p className='capitalize'>{row?.eventsDetails?.title}</p>
            }
        },
        {
            key: 'eventDate', name: 'Event Date ',
            render: (row) => {
                return <>{row?.eventsDetails?.createdAt ? datepipeModel.datetime(row?.eventsDetails?.createdAt) : ""}</>
            }
        },
        {
            key: 'connectMeetDate', name: 'ConnectMeet Date',
            render: (row) => {
                return <>{datepipeModel.datetime(row?.eventsDetails?.date ? row?.eventsDetails?.date : "")}</>
            }
        },
        {
            key: 'participants',
            name: 'Participants',
            render: (row) => {
                const attendees = row?.attendeeDetails || [];
                const displayedAttendees = attendees.slice(0, 3);

                return (
                    <>
                        <div className='flex      items-center flex-wrap gap-x-2 gap-y-1'>
                        {displayedAttendees.map((ele, index) => (
                            <div className=' bg-primary px-2 py-1 rounded-md text-white text-xs capitalize ' key={index}>{ele?.fullName}</div>
                        ))}

{attendees.length > 3 && (
                            <button className=' bg-gray-200 underline px-2 py-1 rounded-md text-black text-xs capitalize' onClick={() => { handleSeeMore(attendees) }} >
                              See More
                            </button>
                        )}
                      
                        </div>
                    
                    </>
                );
            }
        }


        // {
        //     key: 'participants', name: 'Participants',
        //     render: (row) => {
        //         return <>{row?.attendeeDetails?.map((ele) => {

        //             { ele?.fullName }

        //         })}</>
        //     }
        // },
        // Other column definitions for Connect Meet...
    ];

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setFilters({ ...filters, page: 1 });
    };
    const handleInputField = async () => {
        try {

            const res = await axios({
                method: "get",
                url: `${environment.api}api/sample/group-import`,
                responseType: 'blob',

            });
            var blob = new Blob([res.data], {
                type: res.headers["content-type"],
            });
            let downloadAnchor = document.createElement("a")
            downloadAnchor.href = window.URL.createObjectURL(blob);
            downloadAnchor.download = `SampleFile.xlsx`;
            downloadAnchor.click()


        } catch (error) {
            console.error('Error fetching data:', error);
        }


    };
    const getImportData = (p = {}) => {
        setLoading(true)
        loader(true)

        ApiClient.get('api/import/event/history', { ...p })
            .then(res => {
                if (res.success) {
                    loader(false);
                    setImportedData(res.data);
                    toast.success(res.message);
                    setTotal(res?.total);
                }
                setLoader(false);
                setLoading(false)
            });
    }
    // const getImportedData = (p = {}) => {
    //     loader(true);
    //     setLoader(true);

    //     ApiClient.get(`api/import/event/history`, { ...p })
    //         .then(res => {
    //             if (res.success) {
    //                 setImportedData(res.data);
    //                 toast.success(res.message);
    //                 setTotal(res?.total);
    //             }
    //             setLoader(false);
    //         });

    // };

    const getConnectMeetData = (p = {}) => {
        setLoading(true);
        loader(true)
        ApiClient.get(`api/user/event/history`, { ...p })
            .then(res => {
                if (res.success) {
                    setConnectMeetData(res.data);
                    // toast.success(res.message);
                    settotalHistory(res?.total)
                }
                setLoading(false);
                loader(false)
            });
    };

    useEffect(() => {

        getImportData(filters)
        getConnectMeetData(filtershistory)

    }, []);

    useEffect(() => {
if(user?.customerRole?._id === environment?.customerRoleId){
    setActiveTab("connectMeet")
}

    }, [user?.customerRole?._id === environment?.customerRoleId]);
    const clear = () => {
        setFiltershistory({ ...filtershistory, search: '', page: 1 })
        getConnectMeetData({ page: 1, count: 10, search: '', loginId: user?.loginId })
    }
    const filter = (p = {}) => {
        let f = {
            page: 1,
            ...p
        }
        setFiltershistory({ ...filtershistory, ...f })
        getConnectMeetData({ ...f, ...filtershistory })
    }
    const pageChange = (e) => {
        setFilters({ ...filters, page: e });


        getImportData({ ...filters, page: e });
    };
    const sorting = (key) => {
        let sorder = 'asc'
        if (filters.key == key) {
            if (filters.sorder == 'asc') {
                sorder = 'desc'
            } else {
                sorder = 'asc'
            }
        }

        let sortBy = `${key} ${sorder}`;

        setFilters({ ...filters, sortBy, key, sorder })
        getImportData({ sortBy, key, sorder, ...filters })
    }
    const count = (e) => {
        setFilters({ ...filters, count: e })
        getImportData({ ...filters, count: e })
    }
    const pageChangee = (e) => {
        setFiltershistory({ ...filtershistory, page: e });


        getConnectMeetData({ ...filtershistory, page: e });
    };
    const sortingg = (key) => {
        let sorder = 'asc'
        if (filters.key == key) {
            if (filters.sorder == 'asc') {
                sorder = 'desc'
            } else {
                sorder = 'asc'
            }
        }

        let sortBy = `${key} ${sorder}`;

        setFiltershistory({ ...filtershistory, sortBy, key, sorder })
        getConnectMeetData({ sortBy, key, sorder, ...filtershistory })
    }
    const countt = (e) => {
        setFiltershistory({ ...filtershistory, count: e })
        getConnectMeetData({ ...filtershistory, count: e })
    }
    const importFile = (e) => {
        const file = e.target.files[0];
        
        if (file) {
        const reader = new FileReader();
        
        reader.onload = (event) => {
        const base64String = event.target.result;
        
        let payload = {
        groupId: user?.groupId?._id,
        // eventId: eventIdd,
        addedBy: user?._id,
        
        base64String: base64String
        };
        
        ApiClient.post(`api/import/event-group`, payload).then(res => {
        if (res.success) {
        toast.success(res.message);
        loader(true)
        
        setTimeout(() => {
        loader(false)
        getImportData(filters)
        }, 60000);
        }
        });
        };
        
        reader.readAsDataURL(file);
        }
        };
    // const importFile = (e) => {
    //     const file = e.target.files[0];

    //     if (file) {
    //         const reader = new FileReader();

    //         reader.onload = (event) => {
    //             const base64String = event.target.result;

    //             let payload = {
    //                 groupId: user?.groupId?._id,
    //                 // eventId: eventIdd,
    //                 addedBy: user?._id,

    //                 base64String: base64String
    //             };

    //             ApiClient.post(`api/import/event-group`, payload).then(res => {
    //                 if (res.success) {
    //                     toast.success(res.message);
    //                     loader(true)

    //                     setTimeout(() => {
    //                         loader(false)
    //                         getImportData()
    //                     }, 60000);
    //                 }
    //             });
    //         };

    //         reader.readAsDataURL(file);
    //     }
    // };
    
    return (
        <Layout>
            <div className=''>
                {/* /* import File  */}
                {user?.customerRole?._id != environment?.customerRoleId ? <div className="flex items-center justify-end  bg-grey-lighter">
                    {/* <div className="flex flex-col">
                        <h4 className="text-typo text-2xl font-semibold">History</h4>
                    </div> */}
                       
                    <div className='flex gap-4 items-center'>
                     
                        <div className=''>
                            <div className="flex  items-center justify-center bg-grey-lighter">
                                <label className=" flex gap-2 items-center border-dashed border-gray-200 items-center px-2 py-2 bg-white text-blue rounded-lg tracking-wide  border border-blue cursor-pointer ">
                                    <IoMdCloudDownload className='text-md' />

                                    <span className="text-sm leading-normal" onClick={() => handleInputField()}>Sample file download </span>

                                </label>
                            </div>
                        </div>
                        <label htmlFor="fileInput" className="flex gap-2 items-center border-dashed border-gray-200 items-center px-2 py-2 bg-white text-blue rounded-lg tracking-wide border border-blue cursor-pointer">
                            <IoMdCloudUpload className='text-md' />
                            <span className="text-sm leading-normal">Import file</span>
                        </label>
                        <input id="fileInput" type="file" style={{ display: 'none' }} onChange={importFile} />
                    </div>
                </div> : ""}

            </div>
            <div className='mb-8'>
            <div className="flex flex-col">
                        <h4 className="text-typo text-2xl font-semibold">History</h4>
                    </div>
                <div className='flex items-center justify-between gap-4'>

               

            {activeTab === 'connectMeet' ?    <form className="flex items-center max-w-sm pt-6 pb-0" onSubmit={e => {
                            e.preventDefault()
                            filter()
                        }}>
                            <label for="simple-search" className="sr-only">Search</label>
                            <div className="relative w-full">

                                <input type="text" id="simple-search"
                                    value={filtershistory.search}
                                    onChange={e => {
                                        setFiltershistory({ ...filtershistory, search: e.target.value })
                                    }}
                                    className="bg-gray-50 pr-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-orange-500 dark:focus:border-orange-500" placeholder="Search" required
                                />
                                {filtershistory?.search && (
                                    <i
                                        className="fa fa-times absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 text-sm"
                                        aria-hidden="true"
                                        onClick={(e) => clear()}
                                    ></i>
                                )}
                            </div>
                            <button type="submit" class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-orange-500 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                                <span class="sr-only">Search</span>
                            </button>
                        </form> : <form className="flex items-center max-w-sm pt-6 pb-0 invisible" onSubmit={e => {
                            e.preventDefault()
                            filter()
                        }}>
                            <label for="simple-search" className="sr-only">Search</label>
                            <div className="relative w-full">

                                <input type="text" id="simple-search"
                                    value={filtershistory.search}
                                    onChange={e => {
                                        setFiltershistory({ ...filtershistory, search: e.target.value })
                                    }}
                                    className="bg-gray-50 pr-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-orange-500 dark:focus:border-orange-500" placeholder="Search" required
                                />
                                {filtershistory?.search && (
                                    <i
                                        className="fa fa-times absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 text-sm"
                                        aria-hidden="true"
                                        onClick={(e) => clear()}
                                    ></i>
                                )}
                            </div>
                            <button type="submit" class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-orange-500 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                                <span class="sr-only">Search</span>
                            </button>
                        </form>}
                {user?.customerRole?._id === environment?.customerRoleId ?  <button
                        className={`p-2 rounded-md ${activeTab === 'connectMeet' ? 'bg-orange-500' : ''}`}
                        onClick={() => handleTabChange('connectMeet')}
                    >
                        Connect Meet History
                    </button> :   <div className='flex items-center p-2 bg-[#393c3d] inline-flex text-white rounded-lg gap-4'>
                    <button
                        className={`p-2 rounded-md ${activeTab === 'connectMeet' ? 'bg-orange-500' : ''}`}
                        onClick={() => handleTabChange('connectMeet')}
                    >
                        Connect Meet History
                    </button>
                    <button
                        className={`p-2 rounded-md ${activeTab === 'imported' ? 'bg-orange-500' : ''}`}
                        onClick={() => handleTabChange('imported')}
                    >
                        Imported History
                    </button>
                   
                </div>}
                </div>

                {/* <div className='flex gap-4 items-center'>
                    <label htmlFor="fileInput" className="flex gap-2 items-center border-dashed border-gray-200 items-center px-2 py-2 bg-white text-blue rounded-lg tracking-wide border border-blue cursor-pointer">
                        <IoMdCloudUpload className='text-md' />
                        <span className="text-sm leading-normal">Import file</span>
                    </label>
                    <input id="fileInput" type="file" style={{ display: 'none' }} onChange={importFile} />
                </div> */}

                <div className='relative overflow-x-auto shadow-md sm:rounded-lg mt-6'>
                    {!loading ? (
                        activeTab === 'imported' ? (
                            <Table
                                data={importedData}
                                columns={columnsImported}
                                page={filters.page}
                                count={filters.count}
                                total={total}
                                nodata="No imported history yet"
                                result={(e) => {
                                    if (e.event == 'page') pageChange(e.value)
                                    if (e.event == 'sort') sorting(e.value)
                                    if (e.event == 'count') count(e.value)
                                }}
                            />
                        ) : (
                            <Table
                                data={connectMeetData}
                                columns={columnsConnectMeet}
                                page={filtershistory.page}
                                count={filtershistory.count}
                                total={totalHistory}
                                result={(e) => {
                                    if (e.event == 'page') pageChangee(e.value)
                                    if (e.event == 'sort') sortingg(e.value)
                                    if (e.event == 'count') countt(e.value)
                                }}
                                nodata="No Connect Meet history yet"
                            />
                        )
                    ) : <div className='flex items-center justify-center h-32 text-sm'>Loading...</div>}
                </div>
            </div>
            {historyModal ? <>
                <Modal
                    className="!max-w-lg"
                    title=" List "
                    body={<>
                        <div className='flex flex-col gap-4 pt-4'>
                            {dataName?.map((ele) => (
                                <p className='flex items-center gap-4 bg-gray-50 px-4 py-2 capitalize' key={ele.id}><FaUser className='text-primary fill-primary '/> {ele?.fullName}</p>
                            ))}
                        </div>

                    </>}
                    result={e => {
                        setHistoryModal(false)
                    }}
                />
            </> : <></>}
        </Layout>
    );
};

export default History;
