
import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Layout from "../../components/global/layout";
import { useSelector } from 'react-redux';
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { LiaUserSolid } from "react-icons/lia";
import { HiOutlineMail } from "react-icons/hi";
import { decryptId } from "../../components/common/Encryption/encryption";
const GroupRequestDetail = () => {
    const { eventid } = useParams()
    const id = decryptId(eventid)
    const user = useSelector((state) => state.user);
    const [data, setData] = useState([])
    const getData = (p = {}) => {
        loader(true)

        ApiClient.get(`api/group-request/list?eventId=${id}&groupId=${user?.groupId?._id} `).then(res => {
            if (res.success) {
                setData(res.data)
            }
            loader(false)
        })
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <Layout>

            <div className="grid grid-cols-1  gap-4">

         
            {data?.length ? data?.map((ele) => {
                return (<><div className="shwoing-requeted border border-gray-200 shadow-md p-4 mb-4 ">
                   <div className="flex items-center gap-2 mb-3">
                   <h4 className=' text-lg font-bold'>Requested By : </h4>
                    <div className=" flex items-center gap-2 text-gray-800 font-semibold">
                                <h4 className=' text-xl'> <span className=' px-2 py-1 pb-1 text-sm rounded-md ml-2 flex items-center gap-2 capitalize'><LiaUserSolid className="text-xl" />{ele?.requestedByDetails?.fullName}</span></h4>
                                <span className=' px-2 py-1 pb-1 text-sm rounded-md ml-2 flex items-center gap-2'> <HiOutlineMail className="text-xl" />{ele?.requestedByDetails?.email}</span>

                    </div>
                   </div>

                    <div className="grid grid-cols-12 gap-4 ">
                               
                    {ele?.attendeesDetails?.map((element) => {
                                return (
                                    <div className="col-span-12 md:col-span-6  lg:col-span-6  ">
                                        <div className="border border-gray-200 rounded-lg mb-2 p-2">

                                        <p className='mb-1 capitalize flex items-center gap-2 text-sm text-black/70'> <LiaUserSolid />{element?.fullName}</p>
                                        <p className='flex items-center gap-2 text-sm text-black/70'><HiOutlineMail />{element?.email}</p>
                                    </div>
                                </div>)

                            })}
                                    
                               

                    </div>

                  



                </div></>)


            }) : "No Request Available"}

</div>
        </Layout>

    )
}

export default GroupRequestDetail