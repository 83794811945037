const shared={
    check:'Members',
    title:'Assign Member',
    url:'assign-member',
    addApi:'api/event/create',
    editApi:'api/event/edit',
    detailApi:'api/event/details',
    listApi:'api/assign-group/member/listing',
    statusApi:'api/event/edit',
    deleteApi:'api/assign-group/member'
}

export default shared