import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/global/layout';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import './profile.scss'
import methodModel from '../../methods/methods';
import { useSelector } from 'react-redux';
import { LiaUserSolid } from 'react-icons/lia';
import { MdOutlineEmail, MdOutlinePhone } from 'react-icons/md';
import { RiUserShared2Line } from 'react-icons/ri';


const Profile = () => {

  const user = useSelector((state: any) => state.user);
  const [data, setData]: any = useState('');
  const gallaryData = () => {
    loader(true)
    ApiClient.get(`api/user/detail`, { id: user._id }).then(res => {
      if (res.success) {
        setData(res.data)
      }
      loader(false)

    })
  };

 

  
  return (
    <Layout>


      <div className='wrapper_section'>
        <div className='flex items-center  justify-between'>
          <h3 className=' text-lg lg:text-2xl font-semibold text-[#111827]'>Basic Information</h3>
          <Link to="/profile/edit" className="px-2 lg:!px-4 text-sm font-normal bg-orange-500 hover:bg-orange-700 text-white h-10 flex items-center justify-center gap-2 !bg-primary rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">

            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>
            Edit Profile

          </Link>
        </div>

        <div className='inner_part mt-3 md:mt-8 p-6 shadow-box overflow-hidden rounded-lg bg-white'>
          <div className='grid items-start grid-cols-12 gap-4'>

            <div className='col-span-12 md:col-span-6 lg:col-span-6 2xl:col-span-4  h-full pr-4 md:border-r border-dashed border-primary'>
              <div className='flex flex-col items-center gap-4'>
                <div className=''>
                  <img src={methodModel.userImg(user && user.image)} className="h-32 w-32 rounded-md mx-auto object-cover" />
                </div>
                <div className='flex flex-col gap-y-4 bg-gray-50 p-4 rounded-lg w-full'>
                    <div className='border-gray-200 pb-2 border-b text-gray-700 flex items-center  justify-between  gap-2'>
                        <p className='text-md flex items-center gap-2 text-black'>  <LiaUserSolid className='text-xl' /> Name:</p>
                        <span className='firstc text-md text-gray-800'>{user && user.fullName}</span>
                    </div>

                    <div className='border-gray-200 pb-2 border-b text-gray-700 flex items-center  justify-between  gap-2'>
                        <p className='text-md flex items-center gap-2 text-black'>  <MdOutlineEmail className='text-xl' /> Email:</p>
                        <span className=' text-md text-gray-800'>{user && user.email}</span>
                    </div>

                    <div className='border-gray-200 pb-2 border-b text-gray-700 flex items-center  justify-between  gap-2'>
                        <p className='text-md flex items-center gap-2 text-black'>  <RiUserShared2Line className='text-xl' /> Username:</p>
                        <span className='firstc text-md text-gray-800'>{user && user.loginId}</span>
                    </div>

                    <div className='border-gray-200 pb-2 border-b text-gray-700 flex items-center  justify-between  gap-2'>
                        <p className='text-md flex items-center gap-2 text-black'>  <MdOutlinePhone className='text-xl' /> Phone No:</p>
                        <span className='firstc text-md text-gray-800'>{String(user.mobileNo ? '+' + user.mobileNo : 'N/A')}</span>
                    </div>


                 
                </div>
              </div>
            </div>

            <div className='col-span-12 md:col-span-6 lg:col-span-6 2xl:col-span-8 '>
              <div className='mt-2 '>

                <p className='mb-4 text-xl font-medium'>All Groups</p>
                <div className='overflow-auto'>
                  <table className='w-full'>
                    <thead className='border-y border-[#EAECF0]'>
                      <tr className='border-y border-[#EAECF0]'>
                        <th className='text-black !border-l font-normal text-md !border border-[#EAECF0] px-3 py-2  text-left bg-[#F7FAFF] capitalize'>Group</th>
                        <th className='text-black !border-l font-normal text-md !border border-[#EAECF0] px-3 py-2  text-left bg-[#F7FAFF] capitalize'>Role</th>

                      </tr>

                    </thead>
                    <tbody>
                 {user?.groupId?.name ?  <td className='text-[#82838B] !border-l font-normal text-sm !border border-[#EAECF0] px-3 text-left bg-[#F7FAFF] !py-2 capitalize'>{user?.groupId?.name || '--'}</td>:"" }    
                   {user?.groupId?.name ?<td className='text-[#82838B] !border-l font-normal text-sm !border border-[#EAECF0] px-3 text-left bg-[#F7FAFF] !py-2 capitalize'>{user?.groupId?.name ? user.customerRole?.name : "--"}</td> :"" }   

                      {
                        user?.allGroupDetails?.map((e: any) => {
                          return (
                            <>

                              <tr>

                                <td className='text-[#82838B] !border-l font-normal text-sm !border border-[#EAECF0] px-3 text-left bg-[#F7FAFF] !py-2 capitalize'>{e?.groupId?.name || "--"}</td>


                                <td className='text-[#82838B] !border-l font-normal text-sm !border border-[#EAECF0] px-3 text-left bg-[#F7FAFF] !py-2 capitalize'>
                                  {e?.groupId?.name ? (e?.role === "meetManager" ? "Connect Meet Manager" : (e?.role === "assistant" ? "Assistant Group Leader" : e?.role)) : "--"}

                                </td>

                              </tr>
                            </>

                          )

                        })
                      }
                    </tbody>
                  </table>
                </div>

              </div>

            </div>






          </div>




        </div>
      </div>



    </Layout>
  );
};

export default Profile;
// this useefect calls two time i want only one time