const shared={
    check:'Members',
    title:'Member',
    url:'members',
    addApi:'api/members/add',
    editApi:'api/event/edit',
    detailApi:'api/event/details',
    listApi:'api/members/list',
    statusApi:'api/event/edit',
    deleteApi:'api/members'
}

export default shared