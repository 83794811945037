import React, { useState } from 'react';
import Select from 'react-select';
import ApiClient from '../../methods/api/apiClient';
import { useNavigate, useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import loader from '../../methods/loader';
import { decryptId } from '../../components/common/Encryption/encryption';

const RequestGroup = ({ eventDetail = '', data = '', setRequestGroup = '' }: any) => {
  const { eventid } = useParams();
  const user = useSelector((state: any) => state.user);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState(""); 
  const [selectRequiredError, setSelectRequiredError] = useState(false);
  const id = decryptId(eventid);

  const options = eventDetail('Yes')
    .filter((itm: any) => ((itm.memberDetails?.email||itm?.email) !== user?.email))
    .map((itm: any) => ({
      value: itm.id,
      label: itm.memberDetails?.fullName||itm?.fullName
    }));

  const handleSelectChange = (selectedOptions: any) => {
    const selectedIdsArray = selectedOptions.map((option: any) => option.value);
    setSelectedIds(selectedIdsArray);
    setSelectRequiredError(false);
  };

  const handleSubmit = () => {
    if (selectedCheckbox === "connect_with_members" && selectedIds.length === 0) {
      setSelectRequiredError(true);
      return;
    }

    loader(true);
    let payload = {
      eventId: id,
      groupId: data?.groupId?._id,
      requestedBy: user?.email,
      attendeeIds: selectedIds,
      userLoginId: user?.loginId,
      requestType: selectedCheckbox
    };

    ApiClient.post("api/group/request", payload).then(res => {
      if (res) {
        toast(res.message);
        loader(false);
        setRequestGroup(false);
      }
    });
  };

  const handleCheckboxChange = (checkboxName: string) => {
    setSelectedCheckbox(checkboxName);
  };

  const isSubmitDisabled = !selectedCheckbox; // Disable if no checkbox is selected

  return (
    <div className='flex flex-col gap-4 mt-4'>
      <div>
        <div className='flex gap-2 items-center'>
          <input
            className='h-4 w-4 cursor-pointer'
            type="radio"
            checked={selectedCheckbox === "connect_with_members"}
            onChange={() => handleCheckboxChange("connect_with_members")}
          />
          <span className=''>Request specific people for my ConnectMeet for this event </span>
        </div>
        {selectedCheckbox === "connect_with_members" && (
          <div className='mt-2'>
            <Select
              isMulti
              options={options}
              placeholder="Select Members"
              className='capitalize '
              onChange={handleSelectChange}
            />
            {selectRequiredError && <p className="text-red-500">Please select at least one member.</p>}
          </div>
        )}
      </div>
    
      <div>
        <div className='flex gap-2 items-center'>
          <input
            className='h-4 w-4 cursor-pointer'
            type="radio"
            checked={selectedCheckbox === "not_interested"}
            onChange={() => handleCheckboxChange("not_interested")}
          />
          I'm NOT available for a ConnectMeet for this event
        </div>
      </div>
      
      <div>
        <div className='flex gap-2 items-center'>
          <input
            className='h-4 w-4 cursor-pointer'
            type="radio"
            checked={selectedCheckbox === "system_generate"}
            onChange={() => handleCheckboxChange("system_generate")}
          />
          I'm available for a ConnectMeet for this event
        </div>
      </div>
    
      <div className='mt-4 flex items-center justify-end'>
        <button
          className='bg-[#EF7A2B] inline-flex py-2 px-6 flex items-center justify-center gap-x-2 text-white shadow hover:shadow-lg rounded-xl'
          onClick={handleSubmit}
          disabled={isSubmitDisabled} // Disable if no checkbox is selected
        >
          Submit My Request
        </button>
      </div>
    </div>
  );
};

export default RequestGroup;
