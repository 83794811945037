import React, { useState, useEffect } from 'react';
import Layout from '../components/global/layout';
import ApiClient from '../methods/api/apiClient';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import FormControl from "../components/common/FormControl";
import { useDispatch } from 'react-redux';
import loader from '../methods/loader';
const Setting = () => {
  const options = [
    { id: 1, name: ' 1 Month' },
    { id: 2, name: '2 Month' },
    { id: 3, name: '3 Month' },
    { id: 4, name: '4 Month' },
    { id: 5, name: '5 Month' },
    { id: 6, name: '6 Month' },
  ];
  const dispatch=useDispatch()
  const [form, setForm] = useState(6);
  const [memberGroup, setMemberGroup] = useState(3)
  const [getdata, setGetData] = useState()
  const user = useSelector((state) => state?.user);
  
 
  useEffect(() => {
    getSettingData();
  }, []);
  const getSettingData = () => {
    const id = user?.groupId?._id;
    loader(true)
    ApiClient.get(`api/event-group/setting?groupId=${id}`).then(res => {
      if (res.success) {
        loader(false)
        setGetData(res.data);
        if (res?.data && res?.data?.length > 0) {
          setMemberGroup(res?.data[0]?.groupMemberLimit);
          setForm( res?.data[0].historyMonths );
        }
      }
    });
  };

  const handleSave = () => {

    if (getdata && getdata.length > 0) {
      const id = getdata[0]?._id
      let payload = {
        id: id,
        groupMemberLimit: memberGroup,
        historyMonths: form
      }
      ApiClient.put(`api/event-group/setting/update`, payload).then(res => {
        if (res.success) {
          toast(res?.message)
          getSettingData()
        }
      });
    }
    else {
      const id = user?.groupId?._id;
      let payload = {
        groupId: id,
        groupMemberLimit: memberGroup,
        historyMonths: form
      } 
      ApiClient.post(`api/event-group/setting`, payload).then(res => {
        if (res) {
          toast(res?.message)
          getSettingData()
        }
      });
    }
  
  };
  localStorage.setItem("connectMeetHistory" ,form)
  const handleChange = (event) => {
    setMemberGroup(event?.target?.value)
  };

  return (
    <Layout>


<div className='mb-6'>
                    <h3 className="text-2xl font-semibold text-[#111827]"> Settings</h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your Settings</p>
                </div>


      <div className='shadow-box bg-white rounded-lg mt-6 p-2 lg:p-6 w-full '>

        <div className='grid grid-cols-12 gap-4'>
          <div className='col-span-12 md:col-span-6'>
            <div className='inputs_date'>
              <label className='mb-1 block'>My group settings</label>
              <input
                type="text"
                placeholder="Member Group"
                className='relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 border border-gray-100'
                value={memberGroup}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='col-span-12 md:col-span-6'>

            <FormControl
              type="select"
              label="Connect Meet History"
              displayValue="name"
              placeholder="Select Connect Meet History"
              value={form}
              onChange={e => setForm(  e )}
              options={options}
              theme="search"
            />


          </div>
        </div>





        <div className='flex items-center justify-end mt-3'>
          <button
            className='bg-orange-500 px-4 py-2 rounded-lg text-white mt-4'
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Setting;
